<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search
                :moduleapi="`${this.baseApi}/request`"
                title="HR Request"
                :columns="columns"
                routerpath="/hr/addhrrequest"
                :formOptions="formOptions"
                :edit="edit"
                :add="add"
                :canDelete="canDelete"
                :viewFields="viewFields"
                popUpCompnent="addRequest"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoodTableColumnSearch from "../../../components/GoodTableColumnSearch.vue";
import axios from "axios";
const baseApi = process.env.VUE_APP_APIENDPOINT;
export default {
  components: {
    GoodTableColumnSearch,
  },
  data() {
    return {
      baseApi: process.env.VUE_APP_APIENDPOINT,
      viewFields: [
        {
          label: "Employee",
          field: "employee",
          type: "dropdown",
          responseValue: "fullname",
          class: "col-md-4",
        },
        {
          label: "Description",
          field: "description",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Attachment",
          field: "attachment",
          type: "attachment",
          class: "col-md-4",
        },
      ],
      columns: [
        {
          label: "Employee Name",
          field: "employee",
          type: "staticdropdown",
          url: "getUsers",
          responseValue: "fullname",
          filterOptions: {
            enabled: true,
            placeholder: "Search Employee Name",
          },
        },
        {
          label: "Description",
          field: "description",
          filterOptions: {
            enabled: true,
            placeholder: "Search Description",
          },
        },
        {
          label: "Enter By",
          field: "enterby",
          type: "staticdropdown",
          url: "getUsers",
          responseValue: "fullname",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Enter Date",
          field: "created_at",
          type: "datetime",
          time: true,
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Modified By",
          field: "modifiedby",
          type: "staticdropdown",
          url: "getUsers",
          responseValue: "fullname",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Modified Date",
          field: "updated_at",
          time: true,
          type: "datetime",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      formOptions: {
        formTitle: `${this.$route.params.id ? "Edit Request" : "Add Request"}`,
        submitRouterPath: "/hr/hrrequest",
        usersDropdown: true,
        branchDropdown: true,
        designationDropdown: true,
        method: "post",
        action: "add",
        url: `${baseApi}/request`,
        getEditValue: `${baseApi}/requestById`,

        inputFields: [
          {
            label: "Employee",
            name: "employee",
            value: "",
            type: "dropdown",
            class: "col-md-12",
            dropdownname: "employee",
            url: "getEmployee",
            responseValue: "id",
            required: true,
            options: [],
          },
          {
            label: "Description",
            name: "description",
            value: "",
            type: "text",
            class: "col-md-12",
            responseValue: "id",
            required: true,
          },
        ],
      },
      edit: "",
      add: "",
      canDelete: "",
    };
  },
  beforeMount() {
    this.getDropdowns();
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Request") {
          this.edit = item.edit;
          this.add = item.add;
          this.canDelete = item.delete;
          if (item.edit !== 1 && item.add !== 1 && item.delete !== 1 && item.view !== 1) {
            this.$router.push("/");
          }
        }
      });
    }
  },
  methods: {
    async getDropdowns() {
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      this.formOptions.inputFields.map(async (item) => {
        if (item.type == "dropdown" && item.url) {
          const user = {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            url: `${baseApi}/${item.url}`,
          };

          await axios(user)
            .then((response) => {
              response.data.data.data
                ? (item.options = response.data.data.data)
                : (item.options = response.data.data);

              // this.form[item.name] = data[item.name];
              // if (item.name == item.dropdownname) {
              //   item.options.map((user) => {
              //     if (user.id == this.form[item.dropdownname]) {
              //       item.item = user.name;
              //     }
              //   });
              // }
            })
            .catch((error) => console.log(error, "error"));
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
